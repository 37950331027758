import React, { Component,Fragment } from 'react';
import { Row, Col, CardBody, Button, Modal, ModalBody, ModalHeader, Input, } from "reactstrap";
import { setAppCustomTitle } from "../../reducers/ThemeOptions";
import { connect } from "react-redux";
import {isMobile} from 'react-device-detect';
import requestApiService from '../../services/requestApi.service';
import cx from "classnames";
import Common from '../Common';
import ListTable from '../../Common/ListTable';
import Filter from "./Filter";
import aiData from "../../assets/utils/images/agent_no_records.png"
import { showToast, prepareCustomTableData, exportFormData,parseJSON, formatDateTime, formatDate} from '../../Common/Appcommon';
import Loading from '../../Common/Loading';
import NoRecordFound from '../Common/NoRecordFound';
import Select from "react-select";
import parseHtml from 'html-react-parser';
import CallLogAnalysisSummary from "./CallLogAnalysisSummary";
import { subDays } from "date-fns";
import { decode as base64_decode, encode as base64_encode } from 'base-64';
import { tr } from 'date-fns/locale';

class CallLog extends Component {

  exportRecordsCounts = [
    { label: "10000 Records", value: 10000 },
    { label: "20000 Records", value: 20000 },
    { label: "30000 Records", value: 30000 },
    { label: "40000 Records", value: 40000 },
    { label: "50000 Records", value: 50000 },
  ];
  filterOption1 = [
    {value : '1', lable: "Campaign"},
    {value : '2', lable: "Automation"},
  ];
  filterOption2 = [
    {value : '1', lable: "Recipients"},
    {value : '2', lable: "Call Initiated"},
    {value : '3', lable: "Connected Calls"},
    {value : '4', lable: "Not Connected Calls"},
    {value : '5', lable: "Goal Achieved"},
    {value : '6', lable: "Goal Not Achieved"},
  ];
  startDate = subDays(new Date(), 7); 
  endDate = new Date(); 
  dateFilter = {Start_DateTime: [this.startDate, this.endDate],noofdays: 7,};
  constructor(props) {
    super(props);
    let fil_cond = props.match.params.fil_cond !== undefined && props.match.params.fil_cond !== "" ? props.match.params.fil_cond : "";
    let fil_str = "";
    console.log("fil_cond",fil_cond);
    let allowFilter = true;
    let allowBack = false;

    if(fil_cond !== ""){
      fil_cond = parseJSON(base64_decode(fil_cond));
      console.log("fil_cond",fil_cond);
      if(fil_cond["filterType"] == "call_analysis"){
        allowFilter = false;
      }
      allowBack = true;
      if(fil_cond["filterType"] !== "campaign") {
        fil_str = fil_cond["filterValue"];
        let dateStr = fil_str["date"] ? fil_str["date"] : "";
        if(dateStr !== "" && dateStr["noofdays"] !== 1){
          let startDateTime = dateStr["Start_DateTime"]?.[0] || "";
          let endDateTime = dateStr["Start_DateTime"]?.[1] || "";
          startDateTime = formatDateTime(startDateTime);
          endDateTime = formatDateTime(endDateTime);
          dateStr = startDateTime + " - " + endDateTime;
        }else {
          dateStr = "Today";
        }
        const parts = [
          fil_str["agentName"] && fil_str["agentName"],
          fil_str["header"] && fil_str["header"],
          dateStr && dateStr,
        ].filter(Boolean);
        fil_str = parts.join(" | ");
      }
     
    }
    this.state = {
            searchStr: "",
            data: [], // Assuming data is fetched and managed here
            columns: [], // Define columns for your table
            showDetailed: false,
            isModalOpen: false,
            showButtonLoading: false,
            showLoader: false,
            isPlaying: false,
            totalCount: 0, 
            exportLimit: this.exportRecordsCounts[0].value,
            audioStates: {},
            isDisabled: false,
            whatsappFilter: '',
            statusFilter: '',
            dateFilter: {Start_DateTime: subDays(new Date(), 1),noofdays: new Date(),},
            adFilCond : fil_cond,
            adCondStr : fil_str,
            isAllowFilter : allowFilter,
    };
  }
  

  setShowLoaderStatus = async (status = false) => {
    this.setState({
        showLoader: status
    })
  }

  componentDidMount = async () => {
    await this.setShowLoaderStatus(true);
    await this.getCallLogs();
    this.state = { isPlaying: false}
    await this.setShowLoaderStatus();
  };


  componentWillUnmount = async () => { 
    const { audioStates } = this.state;
    Object.keys(audioStates).forEach((index) => {
      if (audioStates[index]?.isPlaying) {
        audioStates[index].audio.pause();
      }
    });
  }

  

getCallLogs = async (searchStr = "", param = {}) => {

        this.setState({ adFilCond: param, dateFilter: param.date, whatsappFilter : param.whatsapp, statusFilter : param.status, }, async () => {
          let params = {
            ENTITY: "AIPROCESSOR",
            OPERATION: "GET_CALL_LOGS",
            SearchStr: searchStr,
            FILTER_COND: this.state.adFilCond, // Use updated state here
          };

          try {
            let formresponse = await requestApiService.processServerRequest(params, "WebV2");
            if (formresponse.issuccess) {
              let data = formresponse.data;
              let lists = data[0] ?? [];
              let columndetails = data[1] ?? [];
              await this.prepareListData(lists, columndetails);
            }
          } catch (error) {
            console.error("Error processing server request:", error);
          }
        });
    
}


prepareListData = async (formData, listFields) => {
  const { audioStates } = this.state;
  let data = [];
  let columns = [];
  let tableData = await prepareCustomTableData(formData, listFields, false, false, this.onViewAccountSettings);
  data = tableData.data;
  let totalCount = Object.keys(data).length;
  columns = tableData.columns;
  data = data.map((item, index) => {
    return {
      ...item,
      id: item.id || `row-${index}`, 
      Call_Cost: item.Call_Cost !== "" ? "$ "+item.Call_Cost : "$ 0.00", // Use string for HTML icon
      Goal_Achievement: item.Goal_Achievement === "Yes" ? "Completed" : "InCompleted"
    };
  });

  columns.push(
    {
      name: "Play Audio",
      selector: 'access',
      minWidth: '95px !important',
      maxWidth: '0% !important',
      faAlignJustify:'center',
      cell: (row) => (
        <div onClick={(event) => this.playList(row['data_row']['Record_url'], row['id'], event)} className="audio-play-button">
          {audioStates[row['id']]?.isPlaying ? (
            <i className="icon-color fi fi-rr-pause"></i> // Pause icon
          ):(
            <i className="icon-color le-form-row fi fi-rr-play"></i> // Play icon
          )}
        </div>  
      ),
      style: {
        padding: 0,
        margin: 'center',
        justifyContent: 'center',
      }
    }
  );

  this.setState({
    data, columns, totalCount : totalCount
  });

}

onClickExports = async () => {
  let {totalCount, exportLimit} = this.state;
  if(totalCount === 0){
      showToast("No data found ", "top-right", "error");
  }else if(totalCount <= 10000){
      this.setState({
          showLoader : true
      })
      let filename = 'Call_Logs';
      await exportFormData('table_37','', filename, exportLimit);
      this.setState({
          showLoader : false
      })
  }else{
      this.handleExportChanges();
      //showToast("A maximum of 5000 records can be download", "top-right", "error");
  }
}

handleExportChanges = (name = "modal", e) => {
  if(name === "records"){
      let value = e.value;
      this.setState({
          exportLimit : value
      });
  }else{
      this.setState(prevState => ({
          isModalOpen: !prevState.isModalOpen,
          exportLimit : this.exportRecordsCounts[0].value
      }));
  }
};

closeModal = (name) => {
  this.setState((prevState) => ({
      isModalOpen: !prevState.isModalOpen,
      errorMessage: '',
      showButtonLoading: false,
  }));
}

  playList = async (audioUrl, rowIndex, event) => {
    const { audioStates } = this.state;


    if(audioUrl != undefined && audioUrl != null && audioUrl != ""){  
      audioUrl = audioUrl.split(",");
      audioUrl = audioUrl[0];
      event.stopPropagation();

      // Pause all currently playing audios
      Object.keys(audioStates).forEach((index) => {
        if (audioStates[index]?.isPlaying) {
          audioStates[index].audio.pause();
          audioStates[index].audio.currentTime = 0; // Reset playback to the start
        }
      });
    
      // If the current row's audio is already playing, toggle it off
      if (audioStates[rowIndex]?.isPlaying) {
        this.setState((prevState) => ({
          audioStates: {
            ...prevState.audioStates,
            [rowIndex]: { isPlaying: false, audio: null },
          },
        }));
        this.getCallLogs();
      } else {
        // Play the new audio
        const audio = new Audio(audioUrl);
        this.getCallLogs();
        audio.play().catch((error) => {
          console.error("Error playing audio:", error);
        });
    
        // Reset state when audio ends
        audio.onended = () => {
          this.setState((prevState) => ({
            audioStates: {
              ...prevState.audioStates,
              [rowIndex]: { isPlaying: false, audio: null },
            },
          }));
          this.getCallLogs();
        };
    
        // Update state to reflect the new playing audio
        this.setState({
          audioStates: {
            [rowIndex]: { isPlaying: true, audio }, // Set only the current row as playing
          },
        });
        this.getCallLogs();
      } 
    }
  if(audioUrl === undefined || audioUrl === null || audioUrl === ""){
    showToast("No Audio URL Found.");
    Object.keys(audioStates).forEach((index) => {
      if (audioStates[index]?.isPlaying) {
        audioStates[index].audio.pause();
        audioStates[index].audio.currentTime = 0; // Reset playback to the start
      }
    });
    this.setState({
      audioStates: { isPlaying: false, audio: null }
    });
    this.getCallLogs();
    }
  };

  handleExportClick = async (index, filename, start, exportLimit) => {
    if(index < 0){
        return;
    }
    this.setState({
        showLoader : true,
        isDisabled : true,
    }) 
    await exportFormData('table_37','', filename, exportLimit, start - 1);
    this.setState({
        showLoader : false,
        isDisabled : false,
    })
}
  
  backAction = () => {
    window.history.back();
  }

  getModalContent = () => {
    let { isModalOpen, errorMessage, totalCount, exportLimit, showButtonLoading } = this.state;
    let noofloops = Math.ceil(totalCount / exportLimit);
    let downloadList = [];
    let startcount = 1;

    for (let i = 0; i < noofloops; i++) {
      let endcount = Math.min(startcount + exportLimit - 1, totalCount); // Ensure endcount does not exceed totalCount
      downloadList.push([startcount, endcount]);
      startcount = endcount + 1;
    }
    return (
        <>
        { totalCount && exportLimit &&

            <Modal isOpen={isModalOpen} toggle={this.handleExportChanges}>
                <ModalHeader toggle={this.closeModal}>
                        <div>
                            <div className={`quickreply-inbox-header`}>
                                <span>Export as CSV</span>
                                {false && <Select
                                    onChange={(eve) => {
                                        this.handleExportChanges("records", eve)
                                    }}
                                    defaultValue={this.exportRecordsCounts[1]}
                                    options={this.exportRecordsCounts}
                                    className={"basic-multi-select col-md-4 mR10"}
                                    classNamePrefix={"le-select"}
                                    isClearable={false}
                                    isDisabled={this.isDisabled}
                                />}
                            </div>
                        </div>
                </ModalHeader>
                <ModalBody>
                <div>
                        {
                            downloadList.length > 0 && 
                            downloadList.map((data, index) => {
                                let label = data[0] + " to " + data[1];
                                let filename = "Call Logs Details " + data[0] + " to " + data[1] + ".csv";
                                let name ="Download <strong>" + label +  "</strong> records as <strong>" + filename + "</strong>";
                                return(
                                    <Fragment key={index}>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <span>{parseHtml(name)}</span>
                                            <span title="Click to Download CSV" className={this.isDisabled ? 'cursor-wait' : 'cursor-p'} onClick={e => this.handleExportClick(this.isDisabled ? -1 : index, filename, data[0], exportLimit)}>
                                            <Loading showLoader={showButtonLoading} mainContent={
                                            <Fragment>
                                            <Button className='theme-bg-g'>
                                            <i class= "fi fi-bs-cloud-download-alt" ></i>
                                            </Button>
                                            </Fragment>
                                            } />
                                            </span>
                                        </div>
                                        <hr />
                                    </Fragment>
                                )
                            })
                        }
                    </div>
                </ModalBody>
            </Modal>
  }
        </>
    );
}

handleSearch = (e) => {
  //this.setState({ searchStr: e.target.value });
  let searchStr = e.target.value;
      this.setState({
          searchStr: searchStr
      })
      this.getCallLogs(searchStr);

};

  getTitleBody = (showDetailed) => {
    if (showDetailed) {
      return "";
    }
    return (
     <div></div>
    );
  };



Maincontant = () => {
  const { data, columns, showDetailed, isModalOpen, whatsappFilter, statusFilter,adCondStr,adFilCond,isAllowFilter} = this.state;
  return (
  <Col md={12}>
      <Row className="ms-0 me-0 gx-0 gy-0">
          <Fragment>
            <div>
                <div className='d-flex-alone'>
                    <span className='mt-2 ms-3'>{adCondStr}</span>
                    {console.log("adFilCond",adFilCond)}
                    { adFilCond !== ""  &&  adFilCond.length > 0 &&
                        <div className='back-action d-flex align-center ms-auto me-3 mt-2'>
                        <a className='cursor-pointer' onClick={(e) => { this.backAction() }}>
                            <span className='d-flex align-center'>
                            <i class="fi fi-br-arrow-left pr-1"></i> <span>Back</span>
                            </span>
                        </a>
                        </div>
                    }
                </div>
                
                {
                    isAllowFilter && 
                    <Col md={12}>
                    <Filter
                        neeedbottomfilter = {true}         
                        callogFilters={true}
                        callLogOption1={this.filterOption1}
                        callLogOption2={this.filterOption2}
                        datevalue={this.dateFilter}
                        getCallLogs={this.getCallLogs}
                        onClickExports={this.onClickExports}
                    /> 
                    </Col> 
                }
            
            </div>
            {
                isAllowFilter && 
                <div>
                    <Col md={12} className="z-0">
                    <CardBody>
                        <CallLogAnalysisSummary 
                            dateFilter={this.dateFilter}
                            whatsappFilters={whatsappFilter}
                            statusFilters={statusFilter}
                            lg={2}
                        />
                    </CardBody>
                </Col>
                </div>
            }
            
            <Col md={12}>
            <div className="data-container-contacts">
              {!showDetailed && (
                <div  className="data-container">
                    {!this.state.showLoader ? ( data.length === 0 ? ( <NoRecordFound img={aiData} isOnlyContent={true} content={"No Users Found."}/>
                      ) : (<ListTable data={data} columns={columns} />)
                    ) : (<Loading showLoader={this.state.showLoader} />)}
                </div>
              )}
            </div>
            {isModalOpen && this.getModalContent()}  
            </Col>            
          </Fragment> 
      </Row>
  </Col>
  );
}


  render() {
    const {showDetailed} = this.state;
    return (
      <Fragment>
        <Common
          title={"Call Logs"}
          titleContent={
            <span>
              {(
                "Call Logs"
              )}
            </span>
          }
          titleBody={this.getTitleBody()}
          mainContent={
            this.Maincontant()
          }
        />
      </Fragment>
    );
  }
}


const mapStateToProps = (state) => ({
  appCustomTitle: state.ThemeOptions.appCustomTitle,
});

const mapDispatchToProps = (dispatch) => ({
  setAppCustomTitle: (title) => dispatch(setAppCustomTitle(title)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CallLog);

