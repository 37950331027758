import React, { Fragment, useState, useEffect } from "react";
import { setAppCustomTitle } from "../../reducers/ThemeOptions";
import { connect } from "react-redux";
import { Row, Col } from "reactstrap";
import CountUp from "react-countup"; 
import requestApi from '../../services/requestApi.service';
import Loading from "../../Common/Loading";
import { decode as base64_decode, encode as base64_encode } from 'base-64';
import { getSessionValue } from "../../Common/Appcommon";

const CallAnalysisSummary = (props) => {
    let { agent, dateFilter,callType,lg = 3,selectedAgent } = props;
    let isAllowClick = getSessionValue('isAllowEditAgent') === true || getSessionValue('isAllowEditAgent') === "true" ? true : false;
    //isAllowClick = false;
    const [summaryData, setSummaryData] = useState([]);
    const [loading, setLoading] = useState(true);
    const getSummaryBox = async () => {
        setLoading(true); 
        const params = {
            ENTITY: "AIPROCESSOR",
            OPERATION: "GET_CALL_ANALYSIS_SUMMARY",
            FORMNAME: 'table_37',
            dateFilter: dateFilter,
            agent: agent,
            callType:callType,            
            isOtherApiReq: true,
        };

        try {
            let response = await requestApi.processServerRequest(params);
            if (response.issuccess) {
                setSummaryData(response.data);  
                setLoading(false); 
            }
        } catch (error) {
            console.error("Error fetching dashboard summary:", error);
        } finally {
            setLoading(false);  
        }
    };
    const openCalllogs = (field,header) =>{
        if(!isAllowClick){
            return;
        }
        let filVal = {
            field : field,
            date : dateFilter,
            agentId : agent,
            agentName : selectedAgent?.label || "",
            header : header,
            callType : callType,
        }
        let param = {
            filterType : "call_analysis",
            filterValue : filVal
        }
        let paramList = JSON.stringify(param);
        paramList= base64_encode(paramList);
        window.location = `#/logs/${paramList}`;
    }

    useEffect(() => {
        getSummaryBox();
    }, [agent, dateFilter,callType]);  

    return (
        <Fragment>
            <Loading 
                showLoader={loading} 
                mainContent={
                    <div className="summary-container">
                        <Row>
                            {summaryData.map((val, index) => {
                                const { header, body, prefix, suffix, subValue,field } = val;
                                const isFloat = body % 1 !== 0;  
                                
                                return (
                                    <Col md="6" lg={lg} key={index} className={`${isAllowClick ? 'cursor-pointer' : ''}`}  onClick={(e) => {openCalllogs(field,header)}}>
                                        <div className="card mb-4 widget-chart text-start">
                                            <div className="widget-chart-content">
                                                <div className="widget-numbers">
                                                    <CountUp
                                                        start={0}
                                                        end={body}  
                                                        separator=","
                                                        decimals={isFloat ? 2 : 0}  
                                                        decimal="."
                                                        prefix={prefix}
                                                        useEasing={false}
                                                        duration={3}
                                                        startOnMount={true}  
                                                        className="vi-success-text call-count"
                                                    />
                                                    <span className="mr-1">
                                                    <span className="call-sub-count vi-success-text">{suffix}</span>
                                                        {Object.keys(subValue).length > 0 &&(
                                                            (() => {
                                                                const { subprefix, subbody, subsuffix } = subValue;
                                                                return (
                                                                    <CountUp
                                                                        start={0}
                                                                        end={subbody}  
                                                                        prefix={subprefix}  
                                                                        suffix={subsuffix}  
                                                                        useEasing={false}
                                                                        duration={3}  
                                                                        className="vi-success-text call-sub-count"
                                                                    />
                                                                );
                                                            })()
                                                        )}
                                                    </span>
                                                </div>
                                                <div className="call-footer">{header}</div>
                                            </div>
                                        </div>
                                    </Col>
                                );
                            })}
                        </Row>
                    </div>
                }
            />
        </Fragment>
    );
};

const mapStateToProps = (state) => ({
    appCustomTitle: state.ThemeOptions.appCustomTitle,
});

const mapDispatchToProps = (dispatch) => ({
    setAppCustomTitle: (title) => dispatch(setAppCustomTitle(title)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CallAnalysisSummary);
