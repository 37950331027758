import React from "react";
import ReactApexChart from "react-apexcharts";
import Loading from "../../Common/Loading";
import { setAppCustomTitle } from "../../reducers/ThemeOptions";
import { connect } from "react-redux";  
import "./index.css";

const VerticalBarChart = ({ chartData, Loader, defaultChartConfig }) => {
    const chartConfig = defaultChartConfig[0]; 
    const categories = chartData[0] || [];
    const seriesData = chartData[1] || [];
    // const label = chartConfig.label;
    const isHorizontal = chartConfig.isHorizontal ;
    const download = chartConfig.download;
    const dataLabels = chartConfig.dataLabel
    const height = chartConfig.height;
    const width = chartConfig.width;
    // Check if there are no records
    const options = {
        chart: {
            type: "bar",
            height: "100%",
            toolbar: {
                tools: {
                    download: download,
                },
            },
        },
        plotOptions: {
            bar: {
                horizontal: isHorizontal,
                columnWidth: "50%",
                dataLabels: {
                    position: 'top',
                },
            },
        },
        dataLabels: {
            enabled: dataLabels,
            formatter: (value) => {
                return value === 0 ? "" : value.toLocaleString();
            },
            style: {
                fontSize:"10px",
                colors: ["#000"], 
            },
            offsetX: 20,
        },
        xaxis: {
            categories: categories,
            labels: {
                rotate: -35,
                rotateAlways: true,
                hideOverlappingLabels: true,
            },
        },
        legend: {
            position: 'top', 
            horizontalAlign: 'center', 
            offsetY: -3,
        },
        stroke: {
            width: 1,
            colors: ['#fff']
        },
        yaxis: {},
        tooltip: {
            shared: false,
            intersect: true,
            y: {
                formatter: function (value) {
                    return value.toLocaleString('en-US', { 
                        minimumFractionDigits: 0, 
                        maximumFractionDigits: 0 
                    });
                }
            }
        },
        colors: ['#034737', '#1e90ff', '#ff6347', '#ffa500'],
    };

    return (
        <Loading
            showLoader = {Loader}
            mainContent=
            {
                !Loader && (
                    <div className="summary-container">
                        <div className="card mb-3 widget-chart text-start">
                            <div className="widget-chart-content">
                                <div className="bar mt-2">
                                    <ReactApexChart
                                        options={options}
                                        series={seriesData}
                                        type="bar"
                                        height={height}
                                        width={width}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        />
    );
};

const mapStateToProps = (state) => ({
    appCustomTitle: state.ThemeOptions.appCustomTitle,
});

const mapDispatchToProps = (dispatch) => ({
    setAppCustomTitle: (title) => dispatch(setAppCustomTitle(title)),
});

export default connect(mapStateToProps, mapDispatchToProps)(VerticalBarChart);
