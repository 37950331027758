import React, { Fragment,useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Card, CardBody, Container, Row, Col, InputGroup, Button } from "reactstrap";
import { connect } from "react-redux";
import { updateOrganizationInfo,getCredentials,getOrganizationInfo, getAgencyInfo } from '../../Common/Appcommon';
import requestApiService from '../../services/requestApi.service';
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from '../Subscription/CheckoutForm';
import { decode as base64_decode } from 'base-64';
import Payments from "../../Components/Subscription/Payments";
import stripe from "./assets/images/stripe.png";
import {
    setAppCustomTitle
} from "../../reducers/ThemeOptions";
import GobackButton from "../../Common/GoBack";
import invoice from "./assets/images/invoice.png"


const ManageSubscription = (props) => {
    const { handleSubmit: handleActionFormSubmit, control, formState: { errors } } = useForm();
    const { selectedPlan, refSubmitBtn, isUsagePage, noofMonths, customFunction, isTrail, processMainLoader } = props;
    const [formData, setFormData] = useState({});
    const [stripePromise,setStripePromise] = useState(null);
    const [paymentDetails,setPaymentDetails] = useState({});
    const [orgDeatails,setOrgDetails] =  useState({});
    const [updatedFormData,setUpdatedFormData] = useState({});
    const [paymentConfirmPage, setPaymentConfirmPage] = useState(false);
    const callPaymentFunc = useRef();

    let brandName = getAgencyInfo("Organization_Name");

    useEffect(() => {
        const fetchStripeCredentials = async () => {
            try {
                let stripe = await getCredentials();
                setStripePromise(stripe);
            } catch (error) {
                console.error("Error fetching Stripe credentials:", error);
            }
        };

        fetchStripeCredentials();

        // Return a cleanup function if necessary
        return () => {
            // Cleanup code here
        };
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            processMainLoader();
            try {
                let payDetails = await getPaymentDetails();
                if (payDetails) {
                    try {
                        payDetails = JSON.parse(payDetails);
                        if (payDetails && Object.keys(payDetails).length > 0) {
                            setPaymentDetails(payDetails);
                        } else {
                            setPaymentDetails({ refill_min: 100 });
                        }
                    } catch (error) {
                        console.error("Invalid JSON string:", error);
                    }
                }
    
                let orgData = getOrganizationInfo("all");
                setOrgDetails(orgData);
    
                if (selectedPlan) {
                    setFormData((prevData) => ({
                        ...prevData,
                        ...selectedPlan,
                    }));
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
    
            processMainLoader();
        };
    
        fetchData();
    
        return () => {
            // Add cleanup code if necessary
        };
    }, [processMainLoader, selectedPlan]); // Include processMainLoader
    


    const submitSubsciptionDetails = async (event) => {
        processMainLoader();
        event.preventDefault();
        let fData = [];
        let newformData = { ...selectedPlan, ...updatedFormData }; 
        fData.push(newformData);
        await updateBillInfo();
        if (callPaymentFunc.current) {
            let result = await callPaymentFunc.current.processPayment();
            if(result){
                setPaymentConfirmPage(true);
                customFunction("hideHeaderBtn");
            }
            processMainLoader();
        }
    }

    const handlePaymentConfirmPage = () => {
        customFunction("sucess_payment");
        setPaymentConfirmPage(false);
    }

    const updateBillInfo = async () =>{
        orgDeatails.refill_min = paymentDetails?.refill_min ?? "";
        let params = {
            "ENTITY": "AIPROCESSOR",
            "OPERATION": "UPDATE_BILLING_INFO",
            "ORG_INFO": orgDeatails,
        }
        let formresponse = await requestApiService.processServerRequest(params, "WebV2");
        if (formresponse.issuccess) {
            updateOrganizationInfo(orgDeatails);
        }
    }
    const getPaymentDetails = async () => {
        let params = {
            "ENTITY": "STRIPE_PROCESSOR",
            "OPERATION": "GET_PAYMENTDETAILS",
        }
        let formresponse = await requestApiService.processServerRequest(params, "WebV2");
        if (formresponse.issuccess) {
            let decodeddata = formresponse.data;
            decodeddata = base64_decode(decodeddata);
            return decodeddata;
        }
    }

    const onInputChange = async (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    const onPayInputChange = async (e) => {
        const { name, value } = e.target;
        setPaymentDetails((prevData) => ({
            ...prevData,
            [name]: value
        }));

        setUpdatedFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    const onBillInputChange = async (e) => {
        const { name, value } = e.target;
        setOrgDetails((prevData) => ({
            ...prevData,
            [name]: value
        }));

        setUpdatedFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    const getMainContainer = () => {
        let current_priod = "Monthly Plan";
        let free_minutes = formData.free_minutes ? formData.free_minutes : 0;
        let plan_cost = formData.plan_cost ? formData.plan_cost : 0;
        let plan_cost_detail = "$" + plan_cost + "/month, paid ";
        let addon_cost_per_call = formData.addon_cost_per_call ? formData.addon_cost_per_call : 0;
        addon_cost_per_call = "$" + addon_cost_per_call + "/minute (Charges after free minutes)";
        let current_plan_mode = "";
        let auth_cost = formData.plan_cost;
        if(noofMonths === 12){
            current_priod = "Annual Plan";
            plan_cost_detail += "annually";
            current_plan_mode = "annual"
            let annual_discount = formData.annual_discount !== undefined && formData.annual_discount !== undefined  && formData.annual_discount !== undefined  ? formData.annual_discount : 0;
            annual_discount = (annual_discount * formData.plan_cost /100).toFixed(0);
            auth_cost = auth_cost - annual_discount;
            plan_cost_detail = "$" + auth_cost + "/month, paid annually";
            auth_cost *= 12;
            auth_cost = auth_cost.toLocaleString();
        }else{
            current_priod = "Monthly Plan";
            plan_cost_detail += "monthly";
            current_plan_mode = "monthly"
        };

        let plan_name = formData.plan_name ? (formData.plan_name + " | " + current_priod) : current_priod;

        return(
            <Fragment>
                <Container className='m-auto max-width-65'>
                    <GobackButton name={isUsagePage ? "update_btn" : (isTrail ? "trail_page" : "back")} customFunction={customFunction}/>
                    <form id="manage-sub-form" name={'managesub'} onSubmit={handleActionFormSubmit((data, event) => submitSubsciptionDetails(event))}>
                        <div className='agency-details'>
                            <div className='field-details'>
                                <Card className='card-br'>
                                    <CardBody className='pb-6'>
                                        <div className='card-fields profile-title-info d-grid'>
                                            <span class="heading" >Plan Information</span>
                                        </div>
                                        <div className="mnge-sub-grp">
                                            <Row className={"manage-sub-row"}>
                                                <Col md={6} className=' mt-3'>
                                                    <label className={'form-label'} htmlFor="plan_name">Subscription Plan</label>
                                                    <Controller
                                                        name="plan_name"
                                                        control={control}
                                                        rules={{ required: formData.plan_name === undefined ? "Please provide Plan" : "" }}
                                                        render={({ field }) => {
                                                            return (
                                                                <input
                                                                    {...field}
                                                                    readOnly= {true}
                                                                    type="text"
                                                                    name="plan_name"
                                                                    id="plan_name"
                                                                    value={plan_name}
                                                                    autoComplete={"off"}
                                                                    onChange={(e) => {
                                                                        onInputChange(e);
                                                                        field.onChange(e);
                                                                    }}
                                                                    className={`form-control ${errors.plan_name ? 'is-invalid' : ''}`}
                                                                />
                                                            );
                                                        }}
                                                    />
                                                    {errors.plan_name && <em className={`error invalid-feedback`}>{errors.plan_name.message}</em>}
                                                </Col>
                                                <Col md={6} className=' mt-3'>
                                                    <label className={'form-label'} htmlFor="plan_cost">Subscription Charges</label>
                                                    <Controller
                                                        name="plan_cost"
                                                        control={control}
                                                        render={({ field }) => {
                                                            return (
                                                                <input
                                                                    {...field}
                                                                    type="text"
                                                                    name="plan_cost"
                                                                    id="plan_cost"
                                                                    maxLength={14}
                                                                    readOnly= {true}
                                                                    value={plan_cost_detail}
                                                                    autoComplete={"off"}
                                                                    onChange={(e) => {
                                                                        onInputChange(e);
                                                                        field.onChange(e);
                                                                    }}
                                                                    className={`form-control ${errors.plan_cost ? 'is-invalid' : ''}`}
                                                                />
                                                            );
                                                        }}
                                                    />
                                                    {errors.sub_charge && <em className={`error invalid-feedback`}>{errors.sub_charge.message}</em>}
                                                </Col>
                                            </Row>
                                            <Row className={"manage-sub-row "}>
                                                <Col md={6} className='  mt-3'>
                                                    <label className={'form-label'} htmlFor="free_minutes">Free Minutes</label>
                                                    <Controller
                                                        name="free_minutes"
                                                        control={control}
                                                        render={({ field }) => {
                                                            return (
                                                                <input
                                                                    {...field}
                                                                    type="text"
                                                                    name="free_minutes"
                                                                    id="free_minutes"
                                                                    maxLength={14}
                                                                    readOnly= {true}
                                                                    value={"Plan Includes: " + (free_minutes * noofMonths ? free_minutes * noofMonths : 0 ) + " Free Minutes"}
                                                                    autoComplete={"off"}
                                                                    onChange={(e) => {
                                                                        onInputChange(e);
                                                                        field.onChange(e);
                                                                    }}
                                                                    className={`form-control ${errors.free_minutes ? 'is-invalid' : ''}`}
                                                                />
                                                            );
                                                        }}
                                                    />
                                                    {errors.free_minutes && <em className={`error invalid-feedback`}>{errors.free_minutes.message}</em>}
                                                </Col>
                                                <Col md={6} className=' mt-3'>
                                                    <label className={'form-label'} htmlFor="addon_cost_per_call">Add-on Minute Charges</label>
                                                    <Controller
                                                        name="addon_cost_per_call"
                                                        control={control}
                                                        render={({ field }) => {
                                                            return (
                                                                <input
                                                                    {...field}
                                                                    type="text"
                                                                    name="addon_cost_per_call"
                                                                    id="addon_cost_per_call"
                                                                    maxLength={14}
                                                                    readOnly= {true}
                                                                    value={addon_cost_per_call}
                                                                    autoComplete={"off"}
                                                                    onChange={(e) => {
                                                                        onInputChange(e);
                                                                        field.onChange(e);
                                                                    }}
                                                                    className={`form-control ${errors.addon_cost_per_call ? 'is-invalid' : ''}`}
                                                                />
                                                            );
                                                        }}
                                                    />
                                                    {errors.addon_cost_per_call && <em className={`error invalid-feedback`}>{errors.addon_cost_per_call.message}</em>}
                                                </Col>
                                            </Row>
                                            <Row className={"manage-sub-row"}>
                                                <Col md={6} className=' mt-3'>
                                                    <label className={'form-label'} htmlFor="refill_min">Refill Add-on Minutes</label>
                                                    <Controller
                                                        name="refill_min"
                                                        control={control}
                                                        rules={{
                                                            required: paymentDetails?.refill_min ? false : "This field is required",
                                                            validate: {
                                                                inRange: (value) => {
                                                                    if(value === "" || value === undefined){
                                                                        value = paymentDetails?.refill_min;
                                                                    }
                                                                    const numberValue = parseInt(value, 10);
                                                                    if (isNaN(numberValue)) return "Please enter a valid number";
                                                                    if (numberValue < 25) return "Refill Minutes must be at least 25";
                                                                    if (numberValue > 500) return "Refill Minutes cannot exceed 500";
                                                                    return true;
                                                                },
                                                            },
                                                        }}
                                                        render={({ field }) => {
                                                            return (
                                                                <InputGroup>
                                                                    <input
                                                                        {...field}
                                                                        type="text"
                                                                        name="refill_min"
                                                                        id="refill_min"
                                                                        min="100"
                                                                        max="500"
                                                                        value={paymentDetails?.refill_min ?? 100}                                                            autoComplete="off"
                                                                        onChange={(e) => {
                                                                            const inputValue = e.target.value;
                                                                            if (inputValue === '' || !isNaN(inputValue)) {
                                                                                field.onChange(inputValue); 
                                                                                onPayInputChange(e); 
                                                                            }
                                                                        }}
                                                                        className={`form-control ${errors.refill_min ? 'is-invalid' : ''}`}
                                                                    />
                                                                    <div className="input-group-text">
                                                                        Minutes
                                                                    </div>
                                                                </InputGroup>
                                                            );
                                                        }}
                                                    />
                                                    {errors.refill_min && <em className={`error d-flex invalid-feedback`}>{errors.refill_min.message}</em>}
                                                    <span className = {"manage-sub-span"}>Your add-on minutes will automatically refill whenever your wallet balance drops below 10% of the previous refill amount.</span>
                                                </Col>
                                            </Row>
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>
                        </div>
                        <div className='agency-details'>
                            <div className='field-details'>
                                <Card className='card-br'>
                                    <CardBody className='pb-6'>
                                        <div className='card-fields profile-title-info d-grid'>
                                            <span class="heading">Payments & Billing Information</span>
                                        </div>
                                        <div className="mnge-sub-grp">
                                            <Row className={"manage-sub-row"}>
                                            {
                                                stripePromise &&
                                                <Elements stripe={stripePromise}>
                                                    <CheckoutForm 
                                                        isUsagePage={isUsagePage} 
                                                        paymentDetails={paymentDetails}
                                                        ref={callPaymentFunc} 
                                                        billingDetails={orgDeatails}
                                                        selectedPlan={selectedPlan}
                                                        noofMonths={noofMonths}
                                                    />
                                                </Elements>
                                            }
                                            </Row>
                                            <Row className = {"manage-sub-row"}>
                                                <Col md={6}>
                                                    <label className={'form-label mt-3'} htmlFor="Name">Bill to Name</label>
                                                    <Controller
                                                        name="Name"
                                                        control={control}
                                                        rules={{ required: (!orgDeatails?.Name || orgDeatails.Name === '') ? "Please provide Billing Name" : "" }}
                                                        render={({ field }) => {
                                                            return (
                                                                <input
                                                                    {...field}
                                                                    type="text"
                                                                    name="Name"
                                                                    id="Name"
                                                                    value={orgDeatails?.Name || ''}
                                                                    autoComplete={"off"}
                                                                    onChange={(e) => {
                                                                        onBillInputChange(e);
                                                                        field.onChange(e);
                                                                    }}
                                                                    className={`form-control ${errors.Name ? 'is-invalid' : ''}`}
                                                                />
                                                            );
                                                        }}
                                                    />
                                                    {errors.Name && <em className={`error invalid-feedback`}>{errors.Name.message}</em>}
                                                </Col>
                                                <Col md={6}>
                                                    <label className={'form-label mt-3'} htmlFor="Email_id">Email</label>
                                                    <Controller
                                                        name="Email_id"
                                                        control={control}
                                                        rules={{ required: (!orgDeatails?.Email_id || orgDeatails.Email_id === '') ? "Please provide Email Address" : "" }}
                                                        render={({ field }) => {
                                                            return (
                                                                <input
                                                                    {...field}
                                                                    type="email"
                                                                    name="Email_id"
                                                                    id="Email_id"
                                                                    value={orgDeatails?.Email_id || ''}
                                                                    autoComplete={"off"}
                                                                    onChange={(e) => {
                                                                        onBillInputChange(e);
                                                                        field.onChange(e);
                                                                    }}
                                                                    className={`form-control ${errors.Email_id ? 'is-invalid' : ''}`}
                                                                />
                                                            );
                                                        }}
                                                    />
                                                    {errors.email && <em className={`error invalid-feedback`}>{errors.email.message}</em>}
                                                </Col>
                                            </Row>
                                            <Row className = {"manage-sub-row"}>
                                                <Col md={6}>
                                                    <label className={'form-label mt-3'} htmlFor="Street_1">Address Line 1</label>
                                                    <Controller
                                                        name="Street_1"
                                                        control={control}
                                                        rules={{
                                                            required: orgDeatails?.Street_1?.trim() === "" ? "Please provide Address Line 1" : false, // Check if empty or undefined
                                                        }}
                                                        render={({ field }) => {
                                                            return (
                                                                <input
                                                                    {...field}
                                                                    type="text"
                                                                    name="Street_1"
                                                                    id="Street_1"
                                                                    value={orgDeatails?.Street_1 ?? ''}
                                                                    autoComplete={"off"}
                                                                    onChange={(e) => {
                                                                        onBillInputChange(e);
                                                                        field.onChange(e);
                                                                    }}
                                                                    className={`form-control ${errors.Street_1 ? 'is-invalid' : ''}`}
                                                                />
                                                            );
                                                        }}
                                                    />
                                                    {errors.Street_1 && <em className={`error invalid-feedback`}>{errors.Street_1.message}</em>}
                                                </Col>

                                                    <Col md={6}>
                                                        <label className={'form-label mt-3'} htmlFor="Street_2">Address Line 2</label>
                                                        <Controller
                                                            name="Street_2"
                                                            control={control}
                                                            rules={{
                                                                required: orgDeatails?.Street_2?.trim() === "" ? "Please provide Address Line 2" : false, // Check if empty or undefined
                                                            }}
                                                            render={({ field }) => {
                                                                return (
                                                                    <input
                                                                        {...field}
                                                                        type="text"
                                                                        name="Street_2"
                                                                        id="Street_2"
                                                                        value={orgDeatails?.Street_2 ?? ''}
                                                                        autoComplete={"off"}
                                                                        onChange={(e) => {
                                                                            onBillInputChange(e);
                                                                            field.onChange(e);
                                                                        }}
                                                                        className={`form-control ${errors.Street_2 ? 'is-invalid' : ''}`}
                                                                    />
                                                                );
                                                            }}
                                                        />
                                                        {errors.Street_2 && <em className={`error invalid-feedback`}>{errors.Street_2.message}</em>}
                                                    </Col>
                                                </Row>
                                                <Row className = {"manage-sub-row"}>
                                                    <Col md={6}>
                                                        <label className={'form-label mt-3'} htmlFor="City">City</label>
                                                        <Controller
                                                            name="City"
                                                            control={control}
                                                            rules={{ required: orgDeatails.City === undefined || orgDeatails.City === "" ? "Please provide City" : "" }}
                                                            render={({ field }) => {
                                                                return (
                                                                    <input
                                                                        {...field}
                                                                        type="text"
                                                                        name="City"
                                                                        id="City"
                                                                        value={orgDeatails.City}
                                                                        autoComplete={"off"}
                                                                        onChange={(e) => {
                                                                            onBillInputChange(e);
                                                                            field.onChange(e);
                                                                        }}
                                                                        className={`form-control ${errors.City ? 'is-invalid' : ''}`}
                                                                    />
                                                                );
                                                            }}
                                                        />
                                                        {errors.City && <em className={`error invalid-feedback`}>{errors.City.message}</em>}
                                                    </Col>
                                                    <Col md={6}>
                                                        <label className={'form-label mt-3'} htmlFor="PinZip_Code">Zip Code</label>
                                                        <Controller
                                                            name="PinZip_Code"
                                                            id="PinZip_Code"
                                                            control={control}
                                                            rules={{
                                                                required: orgDeatails.PinZip_Code === undefined || orgDeatails.PinZip_Code === "" ? "Please provide Zip Code" : "",
                                                                validate: {
                                                                        isNumber: (value) => {
                                                                            return (!isNaN(orgDeatails.PinZip_Code) && orgDeatails.PinZip_Code !== '') ? true : "Please enter a valid number"; // Check if value is a valid number
                                                                        },
                                                                    }, 
                                                                }}
                                                            render={({ field }) => {
                                                                return (
                                                                    <input
                                                                        {...field}
                                                                        type="text"
                                                                        name="PinZip_Code"
                                                                        id="PinZip_Code"
                                                                        value={orgDeatails.PinZip_Code}
                                                                        autoComplete={"off"}
                                                                        onChange={(e) => {
                                                                            onBillInputChange(e);
                                                                            field.onChange(e);
                                                                        }}
                                                                        className={`form-control ${errors.PinZip_Code ? 'is-invalid' : ''}`}
                                                                    />
                                                                );
                                                            }}
                                                        />
                                                        {errors.PinZip_Code && <em className={`error invalid-feedback`}>{errors.PinZip_Code.message}</em>}
                                                    </Col>
                                                </Row >

                                                <Row className = {"manage-sub-row"}>
                                                    <Col md={6}>
                                                        <label className={'form-label mt-3'} htmlFor="State">State</label>
                                                        <Controller
                                                            name="State"
                                                            control={control}
                                                            rules={{ required: orgDeatails.State === undefined || orgDeatails.State === "" ? "Please provide State" : "" }}
                                                            render={({ field }) => {
                                                                return (
                                                                    <input
                                                                        {...field}
                                                                        type="text"
                                                                        name="State"
                                                                        id="State"
                                                                        value={orgDeatails.State}
                                                                        autoComplete={"off"}
                                                                        onChange={(e) => {
                                                                            onBillInputChange(e);
                                                                            field.onChange(e);
                                                                        }}
                                                                        className={`form-control ${errors.State ? 'is-invalid' : ''}`}
                                                                    />
                                                                );
                                                            }}
                                                        />
                                                        {errors.State && <em className={`error invalid-feedback`}>{errors.State.message}</em>}
                                                    </Col>
                                                    <Col md={6}>
                                                        <label className={'form-label mt-3'} htmlFor="Country">Country</label>
                                                        <Controller
                                                            name="Country"
                                                            control={control}
                                                            rules={{ required: orgDeatails.Country === undefined || orgDeatails.Country === "" ? "Please provide Country" : "" }}
                                                            render={({ field }) => {
                                                                return (
                                                                    <input
                                                                        {...field}
                                                                        type="text"
                                                                        name="Country"
                                                                        id="Country"
                                                                        value={orgDeatails.Country}
                                                                        autoComplete={"off"}
                                                                        onChange={(e) => {
                                                                            onBillInputChange(e);
                                                                            field.onChange(e);
                                                                        }}
                                                                        className={`form-control ${errors.Country ? 'is-invalid' : ''}`}
                                                                    />
                                                                );
                                                            }}
                                                        />
                                                        {errors.Country && <em className={`error invalid-feedback`}>{errors.Country.message}</em>}
                                                    </Col>
                                                </Row>
                                                <Button type={"submit"} className="theme-bg-g mt-3">{isUsagePage ? "Update Plan" : "Subscribe Plan"}</Button>
                                                <span className = {"manage-sub-span ms-5 me-5 mt-3 d-flex"}>
                                                    By confirming your subscription, you authorize {brandName} to charge a ${auth_cost}.00 {current_plan_mode} fee and add ${(paymentDetails?.refill_min * formData.addon_cost_per_call).toFixed(2)} for add-on minutes whenever your balance is low. You may cancel your subscription at any time.                                                
                                                </span>
                                                <div className='agent-popover-footer'>
                                                    <img className="manage-sub-img" src={stripe} alt="Stripe Logo" />
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </div>
                            </div>
                            {
                                isUsagePage && 
                                <div className='agency-details mb-3'>
                                    <div className='field-details'>
                                        <Card className='card-br'>
                                            <CardBody className='pb-2'>
                                                <div className='card-fields profile-title-info d-grid'>
                                                    <span class="heading" >Invoice History</span>
                                                </div>
                                            <Payments/>
                                            </CardBody>
                                        </Card>
                                    </div>
                                </div>
                            }
                        <div className='hide'>
                            <button ref={refSubmitBtn} class="btn btn-outline-primary">Subscribe</button>
                        </div>
                    </form>
                </Container>
            </Fragment>
        )
    }

    const getConfirmPayment = () => {
        return (
            <Fragment>
                <Container fluid>
                    <div className="no-records-container">
                        <div className="d-grid align-center">
                            {/* <i class="fi fi-ss-badge-check success-tick-icon"></i> */}
                            <img src={invoice} className="w-25" alt="Stripe Logo"></img>
                            <div className="mt-3 font-color">
                                <span className="h3">Updated Successfully</span>
                                <br/>
                                <span>Your subscription has been updated.</span>
                            </div>
                            <Button className="theme-bg-g mt-3 w-25" style={{"margin": "auto"}} onClick={e => handlePaymentConfirmPage()}>
                                Go to Home
                            </Button>
                        </div>
                    </div>
                </Container>
            </Fragment>
        )
    }

    return(
        <Fragment>
            {
                paymentConfirmPage ? (
                    getConfirmPayment()
                ) : (
                    getMainContainer()
                )
            }
        </Fragment>
    )
}


const mapStateToProps = state => ({
    appCustomTitle: state.ThemeOptions.appCustomTitle
});

const mapDispatchToProps = dispatch => ({
    setAppCustomTitle: title => dispatch(setAppCustomTitle(title)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ManageSubscription);